import { createRouter, createWebHistory } from "vue-router";
import { useStore } from "@/store";

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    name: "register",
    path: "/register",
    component: () => import("../views/RegisterView.vue"),
  },
  {
    name: "register_success",
    path: "/register-success",
    component: () => import("../views/RegisterSuccessView.vue"),
  },
  {
    path: "/flirt/logout",
    name: "logout",
    component: {
      beforeRouteEnter(to, from, next) {
        const store = useStore();
        store.logout();
        next({ name: "login" });
      },
    },
  },
  {
    path: "/flirt/dashboard",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/flirt/diamonds",
    name: "diamonds",
    component: () => import("../views/DiamondsView.vue"),
  },
  {
    path: "/flirt/search",
    name: "search",
    component: () => import("../views/SearchView.vue"),
  },
  {
    path: "/flirt/imprint",
    name: "imprint",
    component: () => import("../views/ImprintView.vue"),
  },
  {
    path: "/flirt/messages",
    name: "dialogues",
    component: () => import("../views/DialogueView.vue"),
  },
  {
    path: "/flirt/message",
    name: "dialogue",
    component: () => import("../views/MessageView.vue"),
  },
  {
    path: "/flirt/wheel",
    name: "wheel",
    component: () => import("../views/WheelView.vue"),
  },
  {
    path: "/flirt/profile",
    name: "profile",
    component: () => import("../views/ProfileView.vue"),
  },
  {
    path: "/flirt/update_profile",
    name: "update_profile",
    component: () => import("../views/EditProfileView.vue"),
  },
  {
    path: "/flirt/update_gallery",
    name: "update_gallery",
    component: () => import("../views/EditGalleryView.vue"),
  },
  {
    path: "/flirt/payment",
    name: "payment",
    component: () => import("../views/PaymentView.vue"),
  },
  {
    path: "/flirt/payment/internal",
    name: "payment_pending",
    component: () => import("../views/Payment/PendingPaymentView.vue"),
  },
  {
    path: "/flirt/payment/success",
    name: "payment_success",
    component: () => import("../views/Payment/SuccessfulPaymentView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  const store = useStore();

  let currentUser = null;
  try{
    currentUser = await store.getCurrentUser();
  } catch(e) {
    console.log(e);
  }

  // if user visits login page and is logged in, redirect to home
  if(to.name == 'login' && currentUser) {
    return { name: "home" };
  }

  // if user visits register page and is logged in, redirect to home
  if(to.name == 'register' && currentUser) {
    return { name: "home" };
  }

  // if user visits register success page and is logged in, redirect to home
  if(to.name == 'register_success' && currentUser) {
    return { name: "home" };
  }

  // if user visits logout page: logout and redirect to login 
  if(to.name == 'logout') {
    await store.logout();
    return { name: "login" };
  }

  const publicPages = ['login', 'register', 'register_success'];
  const isPublic = publicPages.includes(to.name);

  if(!currentUser && !isPublic) {
    return { name: "login" };
  }
  
});

export default router;
