//store/modules/auth.js
import { defineStore } from "pinia";
import axios from "axios";

export const useStore = defineStore({
  id: "flirtwerk",
  state: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    token: localStorage.getItem("token"),
    latestProfiles: JSON.parse(localStorage.getItem("latestProfiles")),
    profiles: JSON.parse(localStorage.getItem("profiles")),
    dialogues: JSON.parse(localStorage.getItem("dialogues")),
    contacts: JSON.parse(localStorage.getItem("contacts")),
    balance: localStorage.getItem("balance"),
    tariffs: JSON.parse(localStorage.getItem("tariffs")),
    transactions: JSON.parse(localStorage.getItem("transactions")),
    latestProfile: JSON.parse(localStorage.getItem("latestProfile")),
    metaFields: JSON.parse(localStorage.getItem("metaFields")),
    gallery: JSON.parse(localStorage.getItem("gallery")),
    payments: JSON.parse(localStorage.getItem("payments")),
    latestTariff: JSON.parse(localStorage.getItem("latestTariff")),
    relation: JSON.parse(localStorage.getItem("relation")),
    messages: JSON.parse(localStorage.getItem("messages")),
    boughtImages: JSON.parse(localStorage.getItem("boughtImages")),
  }),
  actions: {
    async login(form) {
      const res = await axios.post("login", form);
      const token = res.data;
      this.token = token;
      localStorage.setItem("token", token);
    },
    async register(form) {
      await axios.post("register", form);
    },
    async confirm(form) {
      await axios.post("confirm", form);
    },
    async logout() {
      await axios.get("logout");
      this.token = null;
      localStorage.removeItem("token");
      this.user = null;
      localStorage.removeItem("user");
      this.latestProfiles = null;
      localStorage.removeItem("latestProfiles");
      await axios.get("logout", {
        headers: {
          "X-API-KEY": this.token,
        },
      });
    },
    async getCurrentUser() {
      try {
        const res = await axios.get("getCurrentUser", {
          headers: {
            "X-API-KEY": this.token,
          },
        });
        const user = res.data;
        this.user = user;
        localStorage.setItem("user", JSON.stringify(user));
        return user;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async getLatestProfiles() {
      const res = await axios.get("getLatestProfiles", {
        headers: {
          "X-API-KEY": this.token,
        },
      });
      this.latestProfiles = res.data;
      localStorage.setItem("latestProfiles", JSON.stringify(res.data));
      return this.latestProfiles;
    },
    async search(searchCriteria) {
      const res = await axios.post("search", searchCriteria, {
        headers: {
          "X-API-KEY": this.token,
        },
      });
      this.profiles = res.data;
      localStorage.setItem("profiles", JSON.stringify(res.data));
      return this.profiles;
    },
    async getDialogues() {
      const res = await axios.get("getDialogues", {
        headers: {
          "X-API-KEY": this.token,
        },
      });
      this.dialogues = res.data;
      localStorage.setItem("dialogues", JSON.stringify(res.data));
      return this.dialogues;
    },
    async getContacts() {
      const res = await axios.get("getContacts", {
        headers: {
          "X-API-KEY": this.token,
        },
      });
      this.contacts = res.data;
      localStorage.setItem("contacts", JSON.stringify(res.data));
      return this.contacts;
    },
    async getBalance() {
      const res = await axios.get("getBalance", {
        headers: {
          "X-API-KEY": this.token,
        },
      });

      this.balance = res.data;
      localStorage.setItem("balance", this.balance);
      return this.balance;
    },
    async getTariffs() {
      const res = await axios.get("getTariffs", {
        headers: {
          "X-API-KEY": this.token,
        },
      });
      this.tariffs = res.data;
      localStorage.setItem("tariffs", JSON.stringify(res.data));
      return this.tariffs;
    },
    async getTransactions() {
      const res = await axios.get("getTransactions", {
        headers: {
          "X-API-KEY": this.token,
        },
      });
      this.transactions = res.data;
      localStorage.setItem("transactions", JSON.stringify(res.data));
      return this.transactions;
    },
    async getProfileById(id) {
      const res = await axios.get("getUser", {
        headers: {
          "X-API-KEY": this.token,
        },
        params: {
          id: id,
        },
      });
      this.latestProfile = res.data;
      localStorage.setItem("latestProfile", JSON.stringify(res.data));
      return this.latestProfile;
    },
    async getMetaFields() {
      const res = await axios.get("getMetaFields", {
        headers: {
          "X-API-KEY": this.token,
        },
      });
      this.metaFields = res.data;
      localStorage.setItem("metaFields", JSON.stringify(res.data));
      return this.metaFields;
    },
    async getGallery(userId) {
      const res = await axios.get("getGallery", {
        headers: {
          "X-API-KEY": this.token,
        },
        params: {
          id: userId,
        },
      });
      this.gallery = res.data;
      localStorage.setItem("gallery", JSON.stringify(res.data));
      return this.gallery;
    },
    async updateProfile(form) {
      const res = await axios.post("updateProfile", form, {
        headers: {
          "X-API-KEY": this.token,
        },
      });

      return res.data;
    },
    async uploadGallery(form) {
      const res = await axios.post("updateGallery", form, {
        headers: {
          "X-API-KEY": this.token,
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    },
    async deleteImage(id) {
      const res = await axios.get("deleteImage", {
        headers: {
          "X-API-KEY": this.token,
        },
        params: {
          id: id,
        },
      });
      return res.data;
    },
    async buyImage(id) {
      const res = await axios.get("buyImage", {
        headers: {
          "X-API-KEY": this.token,
        },
        params: {
          id: id,
        },
      });
      return res.data;
    },
    async blurImage(id, form) {
      const res = await axios.post("blurImage", form, {
        headers: {
          "X-API-KEY": this.token,
        },
        params: {
          id: id,
        },
      });
      return res.data;
    },
    async unblurImage(id) {
      const res = await axios.get("unblurImage", {
        headers: {
          "X-API-KEY": this.token,
        },
        params: {
          id: id,
        },
      });
      return res.data;
    },
    async payment(paymentId, tariffId) {
      const res = await axios.get("payment", {
        headers: {
          "X-API-KEY": this.token,
        },
        params: {
          t: tariffId,
          type: paymentId,
        },
      });
      return res.data;
    },
    async getPayments() {
      const res = await axios.get("getPayments", {
        headers: {
          "X-API-KEY": this.token,
        },
      });
      this.payments = res.data;
      localStorage.setItem("payments", JSON.stringify(res.data));
      return res.data;
    },
    updateLatestTariff(tariff) {
      localStorage.setItem("latestTariff", JSON.stringify(tariff));
    },
    async getRelationByTarget(target) {
      const res = await axios.get("getRelation", {
        headers: {
          "X-API-KEY": this.token,
        },
        params: {
          target: target,
        },
      });
      this.relation = res.data;
      localStorage.setItem("relation", JSON.stringify(res.data));
      return res.data;
    },
    async getRelationById(id) {
      const res = await axios.get("getRelation", {
        headers: {
          "X-API-KEY": this.token,
        },
        params: {
          id: id,
        },
      });
      this.relation = res.data;
      localStorage.setItem("relation", JSON.stringify(res.data));
      return res.data;
    },
    async sendMessage(form) {
      try {
        const res = await axios.post("sendMessage", form, {
          headers: {
            "X-API-KEY": this.token,
            "Content-Type": "multipart/form-data",
          },
        });
        return res.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getMessages(relationId) {
      const res = await axios.get("getMessages", {
        headers: {
          "X-API-KEY": this.token,
        },
        params: {
          id: relationId,
        },
      });
      this.messages = res.data;
      localStorage.setItem("messages", JSON.stringify(res.data));
      return res.data;
    },
    async getBoughtImages() {
      const res = await axios.get("getBoughtImages", {
        headers: {
          "X-API-KEY": this.token,
        },
      });
      this.boughtImages = res.data;
      localStorage.setItem("boughtImages", JSON.stringify(res.data));
      return res.data;
    },
  },
});
