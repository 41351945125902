import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'

// Router
import router from './router'

// Pinia
import { createPinia } from 'pinia'

// Axios
import axios from 'axios';
axios.defaults.baseURL = 'https://flirtwerk.flirtkit.com/api/app';
//axios.defaults.withCredentials = true;
//axios.defaults.headers.common['Content-Type'] = 'application/json';
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
//axios.defaults.mode = 'no-cors';


// Bootstrap
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Font awesome
import '@fortawesome/fontawesome-free/css/all.css'


import './assets/css/styles.css'

createApp(App)
    .use(createPinia())
    .use(router)
    .mount('#app')
