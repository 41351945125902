<template>
  <router-view />
</template>

<style>
section.landing {
  min-height: 85vh;
  background: linear-gradient(
      to bottom,
      rgba(64, 64, 64, 0.7),
      rgba(64, 64, 64, 0.7)
    ),
    url(./assets/img/coal.jpg) no-repeat bottom center;
  background-size: cover;
  padding-top: 15vh;
  padding-bottom: 15vh;
  padding-left: 10vw;
  padding-right: 10vw;
  text-align: center;
  color: white;
}

footer.footer {
  min-height: 20vh;
  padding-top: 5vh;
  padding-bottom: 5vh;
  padding-left: 5vw;
  padding-right: 5vw;
  background-color: #222222;
  color: white;
}

footer.footer a {
  color: white;
}

.logosection {
  background-color: none;
}

nav {
  background-color: none;
}

.nav-link.btn-custom{
  margin: 0.3rem;
}

.btn-custom,
.btn-custom:hover {
  color: white !important;
  background: linear-gradient(
      to bottom,
      rgba(50, 50, 50, 0.4),
      rgba(50, 50, 50, 0.6)
    ),
    url(./assets/img/metal_pattern.jpg) no-repeat center center !important;
  border-color: #222222 !important;
  border: 1px solid #222222 !important;
}

.badge-custom {
  color: white;
  background-color: darkgray;
  border-color: darkgray;
}

.link-active {
  border-bottom: 3px solid darkgray;
}

.link:hover {
  border-radius: 0.25rem;
  color: darkgray !important;
}

.imagesection {
  min-height: 0vh;
  background: linear-gradient(
      to bottom,
      rgba(215, 184, 178, 0.3),
      rgba(215, 184, 178, 0.2)
    ),
    url(./assets/img/wood_pattern.jpg);
  background-repeat: no-repeat;
  color: none;
  background-size: cover;
  color: white;
  text-align: center;
}

.legal-header {
  background: linear-gradient(
      to bottom,
      rgba(215, 184, 178, 0.3),
      rgba(215, 184, 178, 0.3)
    ),
    url(./assets/img/landscape.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 30vh;
  color: white;
  text-align: center;
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.terms a {
  color: darkgray !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: white !important;
  background-color: #444444 !important;
}

.nav-pills .nav-link {
  color: darkgray !important;
}

.login-background {
  background: url(./assets/img/mine.png) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.form-wrapper h4,
.form-footer a {
  color: darkgray;
}
</style>
